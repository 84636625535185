import React from 'react';

/** CSS rules */
import './SolutionsTemplate.scss';

/** Body content */
import Topbar from '../../components/common/Topbar';
import Footer from '../../components/common/Footer';

import * as SavviBranding from '../../components/SavviBranding';
import { RoundedList } from '../../components/RoundedList';

/** Building blocks */
import { Button, Sitemap } from '../../components/common';

/** Utils */
import { useLocation } from 'react-router';
import { generateBem } from '../../utils/generateBem';

const bemPrefix = 'solutionsTemplate';
const bem = generateBem(bemPrefix);
const SolutionsTemplate = ({
  branding = 'SavviLaunch',
  description,
  actionLabel = 'Get Started',
  actionUrl,
  heroImage,
  isPricingCardTall,
  isRoundedListHiding,
  isSolutionsListHiding,
  solutionsList = [],
  wideSolution,
  pricingList,
}) => {
  const location = useLocation();

  return (
    <>
      <Topbar />
      <div className={bem()}>
        <section
          className={bem([
            'topSection',
            !isRoundedListHiding ? 'topSection--paddingBottom' : null,
          ])}
        >
          <div className={bem('topSectionLeft')}>
            {SavviBranding[branding]()}
            <div className={bem('topSectionSubWrapper')}>
              <p className={bem('topSectionSubheader')}>{description}</p>
              <Button
                className={bem('topSectionAction')}
                // TODO change link to workflows view + financing filter
                href={actionUrl + location.search}
              >
                {actionLabel}
              </Button>
            </div>
          </div>
          <div className={bem('topSectionRight')}>
            <div className={bem('topSectionImageWrapper')}>
              <img
                className={bem('topSectionImage')}
                src={heroImage}
                alt="Savvi Site Example"
              />
            </div>
            {!isRoundedListHiding && (
              <div className={bem('roundedList')}>
                <RoundedList items={solutionsList} zIndex="80" />
              </div>
            )}
          </div>
        </section>
        {!isSolutionsListHiding && (
          <div className={bem('section')}>
            {solutionsList.map(
              (
                { description, image, isLastInNumberedList, title, longTitle, icon },
                solutionIndex,
              ) => {
                const isEven = (solutionIndex + 1) % 2;
                return (
                  !!description && (
                    <section
                      key={`solution-${title}`}
                      className={bem(['sectionRow', isEven ? 'sectionRow--even' : null])}
                    >
                      <div className={bem(['sectionItem', 'sectionItem--leftPadding'])}>
                        <div className={bem('sectionItemRow')}>
                          <img
                            className={bem('sectionItemIcon')}
                            src={icon}
                            alt={`${title} icon`}
                          />
                          <h2>{longTitle || title}</h2>
                        </div>
                        <div className={bem('sectionItemRow')}>
                          <p>{description}</p>
                        </div>
                      </div>
                      <div className={bem('sectionRowNumeralWrapper')}>
                        <div
                          className={bem([
                            'sectionRowVerticalRule',
                            solutionIndex === 0 ? 'sectionRowVerticalRule--hidden' : null,
                          ])}
                        />
                        <div className={bem('sectionRowNumeral')}>
                          0{solutionIndex + 1}
                        </div>
                        <div
                          className={bem([
                            'sectionRowVerticalRule',
                            isLastInNumberedList ||
                            solutionIndex + 1 === solutionsList.length
                              ? 'sectionRowVerticalRule--hidden'
                              : null,
                          ])}
                        />
                      </div>
                      <div className={bem(['sectionItem', 'sectionItem--image'])}>
                        <img
                          className={bem('sectionItemImage')}
                          src={image}
                          alt={`${title} Example UI`}
                        />
                      </div>
                    </section>
                  )
                );
              },
            )}
          </div>
        )}
        <hr className={bem('horizontalRule')} />
        {!!wideSolution?.title && (
          <>
            <div className={bem(['section', 'section--wide'])}>
              <div className={bem('sectionRow')}>
                <div className={bem(['sectionItem', 'sectionItem--wide'])}>
                  <div className={bem('sectionItemRow')}>
                    <img
                      className={bem('sectionItemIcon')}
                      src={wideSolution.icon}
                      alt={`${wideSolution.title} icon`}
                    />
                    <h2>{wideSolution.title}</h2>
                  </div>
                  <div className={bem('sectionItemRow')}>
                    <p>{wideSolution.description}</p>
                  </div>
                  <ul className={bem('sectionItemList')}>
                    {wideSolution.list.map(item => (
                      <li key={`wide-list-item-${item}`}>{item}</li>
                    ))}
                  </ul>
                </div>
                <div className={bem('sectionItem')}>
                  <img
                    className={bem('sectionItemImage')}
                    src={wideSolution.uiExample}
                    alt={`${wideSolution.title} Example UI`}
                  />
                </div>
              </div>
            </div>
            <hr className={bem('horizontalRule')} />
          </>
        )}
        <section className={`${bem(['section', 'section--pricing'])}`}>
          <div className={bem('getStarted')}>
            <p>Get started with</p>
            {SavviBranding[branding]()}
          </div>
          {pricingList.map(
            (
              {
                buttonHref,
                buttonText,
                colHeader,
                isSearchAdded,
                list,
                prePrice,
                price,
                postPrice,
                footerList = [],
              },
              pricingCardIndex,
            ) => (
              <div
                key={`pricing-list-item-${colHeader}`}
                className={bem([
                  'pricingCard',
                  (pricingCardIndex + 1) % 2 ? null : 'pricingCard--even',
                  isPricingCardTall ? 'pricingCard--tall' : null,
                ])}
              >
                <div className={bem('pricingCardList')}>
                  <h4>{colHeader}</h4>
                  <ul>
                    {list.map(item => (
                      <li key={`price-item-${item}`}>{item}</li>
                    ))}
                  </ul>
                </div>
                <div className={bem('pricingCardBottom')}>
                  <div className={bem('pricingCardPrePrice')}>{prePrice}</div>
                  <div className={bem('pricingCardPrice')}>
                    {!!price && <h2>{price}</h2>}
                    <p className={bem('pricingCardPostPrice')}>{postPrice}</p>
                  </div>
                  <Button
                    className={bem('pricingCardAction')}
                    href={`${buttonHref}${isSearchAdded ? location.search : ''}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {buttonText}
                  </Button>
                  <div className={bem('pricingCardFooter')}>
                    {footerList.map(footer => (
                      <h4 key={`price-footer-${footer}`}>{footer}</h4>
                    ))}
                  </div>
                </div>
              </div>
            ),
          )}
        </section>
      </div>
      <Sitemap />
      <Footer />
    </>
  );
};

export default SolutionsTemplate;
