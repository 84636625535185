import cartaLogo from '../assets/images/partnerLogos/cartaLogo.png';
import ganMorrowLogo from '../assets/images/partnerLogos/ganMorrowLogo.png';
import motivateLogo from '../assets/images/partnerLogos/motivateLogo.png';
import startupStackLogo from '../assets/images/partnerLogos/startupStackLogo.png';
import techstarsLogo from '../assets/images/partnerLogos/techstarsLogo.png';
import yCombinatorLogo from '../assets/images/partnerLogos/yCombinatorLogo.png';
import paxMomentumLogo from '../assets/images/partnerLogos/paxMomentumLogo.png';
import dojoLogo from '../assets/images/partnerLogos/dojoLogo.png';
// import launchLogo from '../assets/images/partnerLogos/launchLogo.png';

export const Partners = [
  { label: 'Y-Combinator', img: yCombinatorLogo },
  { label: 'Techstars', img: techstarsLogo },
  { label: 'Pax Momentum', img: paxMomentumLogo },
  { label: 'GAN powered by Morrow', img: ganMorrowLogo, width: 90 },
  { label: 'Dojo', img: dojoLogo },
  { label: 'Startup Stack', img: startupStackLogo },
  { label: 'Carta', img: cartaLogo },
  // { label: 'Launch', img: launchLogo, width: 90 },
  { label: 'Motivate', img: motivateLogo, width: 120 },
];
