import investorsExample from '../../assets/images/examples/investors.png';

import buildingIcon from '../../assets/images/icons/building-icon.svg';
import usersIcon from '../../assets/images/icons/users-icon.svg';
import scalesIcon from '../../assets/images/icons/scales-icon.svg';
import aiIcon from '../../assets/images/icons/ai-icon.svg';
import libraryIcon from '../../assets/images/icons/library-icon.svg';

import entityAssessmentExample from '../../assets/images/examples/entity-assessment.png';
import founderOnboardingExample from '../../assets/images/examples/founder-onboarding.png';
import capTableExample from '../../assets/images/examples/cap-table.png';
import goWorkflowLibraryExample from '../../assets/images/examples/go-workflow-library.png';

export const GoData = {
  branding: 'SavviFormation',
  description:
    'The most streamlined way to get new ventures formed, launched, and ready for scale.',
  heroImage: investorsExample,
  actionUrl: 'https://app.savvi.legal/login',
  solutionsList: [
    {
      icon: buildingIcon,
      image: entityAssessmentExample,
      title: 'Entity Setup & Compliance',
      description:
        'Form new entities, or onboard existing ones to manage compliance through Savvi. Share documentation with a Savvi partner bank for streamlined banking setup.',
    },
    {
      icon: usersIcon,
      image: founderOnboardingExample,
      title: 'Founder Onboarding',
      description:
        'Assign founder IP to your entity, issue founder shares, and appoint the initial board members and officers.',
    },
    {
      icon: scalesIcon,
      image: capTableExample,
      isLastInNumberedList: true,
      title: 'Cap Table & Ledger',
      longTitle: 'Cap Table & Corporate Ledger',
      description:
        "Generate legal documents and issue equity, with associated cap table csv and a shareable corporate ledger to serve as a single source of truth for your company's equity history.",
    },
    {
      icon: libraryIcon,
      title: 'Workflows & Templates',
    },
    {
      icon: aiIcon,
      iconClassName: 'RoundedList__floatingIcon',
      title: 'AI-Enabled Services',
    },
  ],
  wideSolution: {
    icon: libraryIcon,
    title: 'GO Workflow Library',
    description:
      'Leverage a library of 50+ workflow apps with curated templates to streamline your standard legal operations. GO package includes:',
    list: ['Equity Management', 'Team Management', 'Board & Governance', 'NDAs'],
    uiExample: goWorkflowLibraryExample,
  },
  pricingList: [
    {
      buttonHref: 'https://app.savvi.legal/login',
      isSearchAdded: true,
      buttonText: 'Get Started',
      colHeader: 'Pre-Formation',
      list: [
        'Entity Filing',
        'EIN Filing',
        'State Compliance',
        'Organizational Documents',
        'Foreign Entity Qualification',
        'Bank Account Setup Support',
      ],
      prePrice: 'Basic Subscription',
      price: 'FREE',
      footerList: [
        'Registered Agent add-on ($99/yr per state)',
        'Filing Agent add-on ($99/yr per state)',
        'state fees (vary by state)',
      ],
    },
    {
      buttonHref: 'https://app.savvi.legal/login',
      isSearchAdded: true,
      buttonText: 'Try it Free',
      colHeader: 'Post-Formation',
      list: [
        'Corporate Transparency Act Filings',
        'Founder Onboarding',
        'Cap Table & Ledger Creation',
        'Workflow & Template Library',
        'Chat Support',
      ],
      prePrice: 'Starter Subscription',
      price: '$499',
      postPrice: 'per year',
      // footerList: ['First 5 documents are free'],
    },
    {
      buttonHref: `https://share.hsforms.com/10Ty4ly4DTpOwt73BE9qXfQ2rvzm`,
      buttonText: 'Schedule Assessment',
      colHeader: 'Add-on Services',
      list: [
        'Assisted Formation',
        'Entity Conversion',
        'Assisted Equity Grant',
        'Cap Table Management',
        'Post-Formation Audit',
        'Counsel Network',
      ],
      postPrice: 'Free formation assessment and service quote',
    },
  ],
};
