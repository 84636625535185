import investorsExample from '../../assets/images/examples/investors.png';

import searchIcon from '../../assets/images/icons/search-icon.svg';
import stackIcon from '../../assets/images/icons/stack-icon.svg';
import handshakeIcon from '../../assets/images/icons/handshake-icon.svg';
import documentIcon from '../../assets/images/icons/document-icon.svg';
import libraryIcon from '../../assets/images/icons/library-icon.svg';

import allocationRequestExample from '../../assets/images/examples/allocation-request.png';
import dataroomExample from '../../assets/images/examples/dataroom.png';
import checklistExample from '../../assets/images/examples/checklist.png';
import projectsExample from '../../assets/images/examples/projects.png';

export const RaiseData = {
  branding: 'SavviFinancings',
  description:
    "Streamline diligence, financing ops, and investor relations with Savvi's financing toolkit and services.",
  heroImage: investorsExample,
  actionLabel: 'Try it Free',
  actionUrl: 'https://app.savvi.legal/login',
  solutionsList: [
    {
      icon: searchIcon,
      image: checklistExample,
      title: 'AI-Enabled Diligence',
      longTitle: 'AI Diligence Tools & Services',
      description:
        'Replace pre-diligence fire drills with peace of mind. Receive AI-generated Ledgers to identify gaps in legal documentation and compliance. Access workflows and add-on services for both clean-up and upkeep.',
    },
    {
      icon: stackIcon,
      image: dataroomExample,
      title: 'Data Rooms',
      longTitle: 'Data Room Creation & Upkeep',
      description:
        'Minimize diligence turn-around and legal fees. Generate integrated data rooms with time-saving diligence reports. Manage gating for reviewers and track activity.',
    },
    {
      icon: handshakeIcon,
      image: allocationRequestExample,
      isLastInNumberedList: true,
      title: 'Investor Relations',
      longTitle: 'Investor Onboarding & Reporting',
      description:
        'Unlock scale and insights with your investor funnel & relations. Generate one link to share your financing data room & opt-in form with prospective investors. Send investor Deal Rooms for personalized diligence, signatures, and wires. Add investors to your Investor Reporting Portal for information rights and updates. Track investor engagement and activity logs at every step of the funnel.',
    },
    {
      icon: libraryIcon,
      title: 'Workflow Library',
    },
    {
      icon: documentIcon,
      title: 'Integrated Services',
    },
  ],
  wideSolution: {
    icon: libraryIcon,
    title: 'Financing Workflows & Services',
    description:
      "Streamline financing operations with Savvi's library of automated workflows. Leverage flat-fee services from our curated network of corporate paralegals and attorneys. Supported financings include:",
    list: [
      'SAFE Notes',
      'Convertible Notes',
      'Preferred Stock',
      'Roll-up Vehicles (SPVs)',
      'M&A',
      'Crowdfunding',
    ],
    uiExample: projectsExample,
  },
  pricingList: [
    {
      buttonHref: 'https://app.savvi.legal/login',
      isSearchAdded: true,
      buttonText: 'Try it Free',
      colHeader: 'Self-Serve Financings',
      list: [
        'SAFEs & Convertible Notes',
        'Diligence Ledger Creation',
        'Data/Deal Rooms',
        'Corporate Transparency Filings',
        'Investor Reporting',
        'Onboarding Call + Chat Support',
      ],
      prePrice: 'Starting at',
      price: '$499',
      postPrice: 'per year',
    },
    {
      buttonHref: `https://share.hsforms.com/10Ty4ly4DTpOwt73BE9qXfQ2rvzm`,
      buttonText: 'Schedule Demo',
      colHeader: 'Assisted Financings',
      list: [
        'Includes everything in Self-Serve',
        'Pre-Diligence Legal Audit',
        'Assisted Data Room Setup',
        'Financing Support Calls',
        'Roll-up Vehicle Setup (SPVs)',
      ],
      prePrice: 'Starting at',
      price: '$2,000',
      postPrice: 'per financing',
    },
    {
      buttonHref: `https://share.hsforms.com/10Ty4ly4DTpOwt73BE9qXfQ2rvzm`,
      buttonText: 'Schedule Assessment',
      colHeader: 'Full-Service Add-ons',
      list: [
        'Financing Filings',
        'Preferred Stock & M&A Transactions',
        'Financing Administration',
        'Counsel Network',
        'Entity Conversion',
        'Fundraising & Exit Strategy',
      ],
      postPrice: 'Free assessment and quote',
    },
  ],
};
