import investorsExample from '../../assets/images/examples/investors.png';

import searchIcon from '../../assets/images/icons/search-icon.svg';
import stackIcon from '../../assets/images/icons/stack-icon.svg';
import handshakeIcon from '../../assets/images/icons/handshake-icon.svg';
import documentIcon from '../../assets/images/icons/document-icon.svg';
import scalesIcon from '../../assets/images/icons/scales-icon.svg';

import homepageExample from '../../assets/images/examples/home-page.png';
import dataroomExample from '../../assets/images/examples/dataroom.png';
import checklistExample from '../../assets/images/examples/checklist.png';
import projectsExample from '../../assets/images/examples/projects.png';

export const FundData = {
  branding: 'SavviFunds',
  description:
    'Unlock front & back-office scale with end-to-end solutions and AI-enabled services.',
  heroImage: investorsExample,
  actionLabel: 'Schedule a Demo',
  actionUrl: 'https://share.hsforms.com/10Ty4ly4DTpOwt73BE9qXfQ2rvzm',
  solutionsList: [
    {
      icon: documentIcon,
      image: homepageExample,
      title: 'Formation & Compliance',
      description:
        'One-stop shop for fund formation and compliance for any fund structure, including traditional, custom, and emerging models (i.e., rolling subscriptions, LP series).',
    },
    {
      icon: handshakeIcon,
      image: dataroomExample,
      title: 'Fundraising & LP Relations',
      description:
        'Manage LP fundraising, onboarding, and reporting with ease. Impress investors with a white-labeled digital portal.',
    },
    {
      icon: stackIcon,
      image: checklistExample,
      title: 'Diligence & Deals',
      description:
        'Streamline your diligence and deal operations with integrated data rooms, checklists, and automated deal docs.',
    },
    {
      icon: searchIcon,
      image: investorsExample,
      title: 'Portfolio & Board Tools',
      description:
        'Standardize information rights and standardized board operations across portfolio companies.',
    },
    {
      icon: scalesIcon,
      image: projectsExample,
      title: 'Sidecar SPVs',
      description: 'Quickly spin-up and manage SPVs for just-in-time capital.',
    },
  ],
  pricingList: [
    {
      buttonHref: 'https://share.hsforms.com/10Ty4ly4DTpOwt73BE9qXfQ2rvzm',
      buttonText: 'Schedule a Demo',
      colHeader: 'Investment Operations',
      list: [
        'AI Diligence Ledgers',
        'Deal Doc Management',
        'Portfolio Reporting ',
        'Board Workflows',
        'Task Dashboard',
      ],
      price: 'Free',
    },
    {
      buttonHref: `https://share.hsforms.com/10Ty4ly4DTpOwt73BE9qXfQ2rvzm`,
      buttonText: 'Talk to Sales',
      colHeader: 'Fund Operations',
      list: [
        'Fundraising Workflows',
        'Formation & Filing Services',
        'Fund Document Automation',
        'LP Portal',
        'Fund Document Analysis',
      ],
      prePrice: 'Starting at',
      price: '$6,000',
      postPrice: 'for first year',
    },
    {
      buttonHref: `https://share.hsforms.com/10Ty4ly4DTpOwt73BE9qXfQ2rvzm`,
      buttonText: 'Schedule Assessment',
      colHeader: 'Add-on Services',
      list: [
        'Legal Diligence Audits',
        'Fund Compliance',
        'Financing Filings',
        'Template Automation',
        'Document Drafting',
        'Fund & Deal Counsel',
        'Custom Integrations',
      ],
      prePrice: 'Free fund assessment & quote',
    },
  ],
};
