import React from 'react';

import './RoundedList.scss';
import { generateBem } from '../../utils/generateBem';

const bem = generateBem('RoundedList');
/**
 * Currently, this component only has support for three items.
 * To use more items, you'll have to write more css rules.
 */
const RoundedList = ({
  iconClassName,
  items,
  zIndex = 0,
  offsetLeft = null,
  offsetTop = null,
}) => {
  const arrayLength = items.length;
  let itemCounter = 0;
  const list = items.map(({ icon, title }) => {
    let className;
    if (itemCounter === 0) {
      className = 'item--first';
    } else if (itemCounter && itemCounter < arrayLength - 1) {
      className = 'item--mid';
    } else {
      className = 'item--last';
    }
    ++itemCounter;
    return (
      <li key={`rounded-list-item-${title}`} className={bem(['item', className])}>
        <span className={bem('hr--hack')}></span>
        <div className={bem('imgTitle--wrapper')}>
          <div className={bem('imgWrapper')}>
            <img className={iconClassName} src={icon} alt={title} />
          </div>
          <div className={bem('title')}>{title}</div>
        </div>
      </li>
    );
  });

  let style = {
    left: offsetLeft,
    top: offsetTop,
    zIndex: zIndex,
  };

  return (
    <div className={bem('wrapper')} style={style}>
      <ul className={bem('list')}>{list}</ul>
    </div>
  );
};

export { RoundedList };
