import React, { useCallback } from 'react';

import { Button, TooltipAction } from '../../components/common';
import { useLocation as location } from 'react-router';
import Topbar from '../../components/common/Topbar';
import checkMark from '../../assets/images/icons/checkmark-icon.svg';
import Footer from '../../components/common/Footer';
import Sitemap from '../../components/common/Sitemap';
import { isEmpty } from 'lodash';

import './Plans.scss';
import UseWindowScroll from '../../utils/UseWindowScroll';

// const upto = units => (
//   <div className="Plans__upToWrapper">
//     <div className="Plans__upTo--small">up to</div>
//     <div className="Plans__units">{units}</div>
//   </div>
// );
const check = <img alt="check-mark" src={checkMark} className="Plans__checkMark" />;
const dash = _ => <b className="Plans__dash">-</b>;
// const included = count => (
//   <div className="Plans__upToWrapper">
//     <div className="Plans__units">{count}</div>
//     <div className="Plans__upTo--small">included</div>
//   </div>
// );
const compileRow = row => {
  return (
    <div key={JSON.stringify(row[0])} className="Plans__tieredContainer">
      <div className="Plans__tierFeature">
        <p>
          {row[0]} <TooltipAction text={row[5]} />
        </p>
      </div>
      <div className="Plans__tierInclusion Plans__tierInclusion--bordered">{row[1]}</div>
      <div className="Plans__tierInclusion Plans__tierInclusion--bordered">{row[2]}</div>
      <div className="Plans__tierInclusion Plans__tierInclusion--bordered">{row[3]}</div>
      <div className="Plans__tierInclusion">{row[4]}</div>
    </div>
  );
};

const tierDictionary = [
  [
    'Doc Send & Sign',
    '5 included',
    check,
    check,
    check,
    'Generate docs to send for review and signature collection, recorded in the Doc Ledger.',
  ],
  [
    'AI Doc Analysis',
    '$2/doc',
    check,
    check,
    check,
    'Import legal docs to receive an AI-generated ledger and organized File Cabinet.',
  ],
  [
    'Doc Ledgers',
    'up to 20 Docs',
    'up to 250 Docs',
    'up to 1k docs',
    'Custom',
    'Reports that summarize legal docs by terms & relationship, enabling transparency, diligence, and peace of mind.',
  ],
  [
    'Data rooms',
    check,
    check,
    check,
    check,
    'Create secure data rooms to share sensitive documents, links, & reports. Manage access & track engagement.',
  ],
  [
    'Shared Forms',
    check,
    check,
    check,
    check,
    'Share forms to collect inputs needed for document creation, diligence, and filings.',
  ],
  [
    'Workspaces',
    check,
    check,
    check,
    check,
    'Collaborative, secure spaces for individuals & teams to manage document-based data, operations, and transactions.',
  ],
  [
    'Dashboards',
    check,
    check,
    check,
    check,
    "Track and manage all of your document & diligence to-do's from one integrated dashboard.",
  ],
  [
    'Providers',
    check,
    check,
    check,
    check,
    'Connect with integrated Service Providers to access specialized services that integrate with your workflows.',
  ],
  [
    'Workflows',
    check,
    check,
    check,
    check,
    'Access a standardized workflow library with integrated forms, templates, data rooms, signatures, filings, & educational content.',
  ],
  [
    'Templates',
    dash(),
    check,
    check,
    check,
    'Leverage our growing library of legal templates for document automation & drafting.',
  ],
  [
    'Portals',
    dash(),
    check,
    check,
    check,
    'Unlock batch transactions & onboarding. Generate and share links with multiple recipients for accessing forms, documents, data rooms, & reports.',
  ],
  [
    'Revisions',
    dash(),
    check,
    check,
    check,
    'Enable redlining & revisions for any document generated on the platform.',
  ],
  [
    'Portfolios',
    dash(),
    dash(),
    'up to 5 entities',
    '$/entity',
    'Connect Workspaces to Portfolios for shared subscriptions, teams, & Workflow libraries. ',
  ],
  [
    'Custom Logic',
    dash(),
    dash(),
    check,
    'Custom',
    'Host custom Workflows & templates for use within your connected Workspaces.',
  ],
  [
    'Custom Branding',
    dash(),
    dash(),
    check,
    check,
    'Set up a custom URL and branding for your front-office interactions.',
  ],
  [
    'Money Movement',
    dash(),
    dash(),
    dash(),
    check,
    'Enable wire transfers and billing within your Portfolio.',
  ],
  [
    'Integrations',
    dash(),
    dash(),
    dash(),
    check,
    'Access our API for custom integrations.',
  ],
  [
    'Seats',
    '1 seat',
    '3 seats',
    '8 seats',
    'Custom',
    'Invite in-house our outsourced team members to collaborate.',
  ],
];
const getTierRows = _ => {
  let rows = [];
  for (let row of tierDictionary) {
    rows.push(compileRow(row));
  }
  return rows;
};

const Plans = () => {
  const { scrollY } = UseWindowScroll();
  let stickyHeader = useCallback(
    node => {
      if (!isEmpty(node) && scrollY > 270) {
        node.classList.add('Plans__tieredColumnsHeaders--stuck');
      } else if (!isEmpty(node) && scrollY < 270) {
        node.classList.remove('Plans__tieredColumnsHeaders--stuck');
      }
    },
    [scrollY],
  );
  const { search } = location();
  const activateHubspotChatter = e => {
    e.preventDefault();
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.open();
    } else {
      window.hsConversationsOnReady = [
        () => {
          window.HubSpotConversations.widget.open();
        },
      ];
    }
  };
  return (
    <>
      <Topbar isPlans />
      <div className="Plans">
        <div className="Plans__wrapper">
          <div className="Plans__introductionText">
            <div className="Plans__introductionText--large">Subscription Plans</div>
            <div className="Plans__introductionText--normal">
              Lifecycle SaaS for your legal, financing, and compliance operations.
            </div>
          </div>
          <div className="Plans__tieredColumns">
            <div ref={stickyHeader} className="Plans__tieredColumnsHeaders">
              <div className="Plans__tier">
                <Button
                  className="Plans__getStarted"
                  href={`https://app.savvi.legal/login${search}`}
                  size="sm"
                >
                  Get Started
                </Button>
              </div>
              <div className="Plans__tier">
                <div className="Plans__tierType">Basic</div>
                <div className="Plans__tierCost">Free</div>
              </div>
              <div className="Plans__tier">
                <div className="Plans__tierType">Starter</div>
                <div className="Plans__tierCost">$499</div>
                <span className="Plans__tierLength">per year</span>
              </div>
              <div className="Plans__tier">
                <div className="Plans__tierType">Advanced</div>
                <div className="Plans__tierCost">$167</div>
                <span className="Plans__tierLength">per month</span>
              </div>
              <div className="Plans__tier">
                <div className="Plans__tierType">Premium</div>
                <a
                  className="Plans__tierCallUs"
                  href="https://meetings.hubspot.com/onboarding48/master-onboarding-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Talk to us
                </a>
              </div>
            </div>
            {getTierRows()}
          </div>
          <div className="Plans__relief--md">&nbsp;</div>
          <div className="Plans__wrapper">
            <div className="Plans__introductionText">
              <div className="Plans__introductionText--large">Still have questions?</div>
              <div className="Plans__introductionText--normal">
                We'd be happy to help. Feel free to{' '}
                <Button size="sm" buttonType="link" onClick={activateHubspotChatter}>
                  contact us via chat
                </Button>{' '}
                during business hours or at{' '}
                <a href="mailto:onboarding@savvi.legal">onboarding@savvi.legal</a>.
              </div>
              <div>
                <div className="Plans__relief">&nbsp;</div>
                <Button
                  className="Plans__contactUs"
                  href="https://meetings.hubspot.com/onboarding48/master-onboarding-link"
                  size="sm"
                >
                  Talk to us
                </Button>
              </div>
            </div>
          </div>
          <div className="Plans__relief--lg">&nbsp;</div>
        </div>
        <Sitemap />
        <Footer />
      </div>
    </>
  );
};

export default Plans;
