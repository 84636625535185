import React, { useRef } from 'react';

import './LargeDropdown.scss';
import { HorizontalCard } from '../HorizontalCard';
import { useBEM } from '../../../utils/UseBEM';

const LargeDropdown = ({ items, placement, componentClassName = '' }) => {
  const dropdown = useRef();
  const bem = useBEM('LargeDropdown');

  const components = items.map(item => (
    <HorizontalCard key={`horizontal-card-${item.title}`} {...item} />
  ));

  return (
    <div className={bem('wrapper')}>
      <div className={`${bem('components')} ${componentClassName}`} ref={dropdown}>
        {components}
      </div>
    </div>
  );
};

export { LargeDropdown };
