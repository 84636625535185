function imageCollection(srcs) {
  const practicallyInvisible = {
    position: 'absolute',
    left: '-200px',
    top: '-200px',
    width: '1px',
    height: '1px',
    opacity: '0.0',
  };
  let images = [];
  for (let i of srcs) {
    images.push(
      <img
        key={`dropdown-icon-${i}`}
        src={`${i}`}
        alt={`${i}`}
        style={practicallyInvisible}
      />,
    );
  }
  return <div style={practicallyInvisible}>{images}</div>;
}
function useImagePreloader(urls) {
  return imageCollection(urls);
}

export { useImagePreloader };
