import React from 'react';

import './SolutionsList.scss';
import { SolutionCard } from '../SolutionCard';
import { generateBem } from '../../utils/generateBem';

const bem = generateBem('SolutionsList');

export const SolutionsList = ({ items, title }) => (
  <div className={bem('wrapper')}>
    <h2 className={bem('title')}>{title}</h2>
    <div className={bem('items')}>
      {items.map(item => (
        <SolutionCard key={`solution-card-${item.title}`} {...item} />
      ))}
    </div>
  </div>
);
