import { React, useEffect, useRef, useState } from 'react';

/** Utils */
import { generateBem } from '../../../utils/generateBem';

import { LargeDropdown } from '../LargeDropdown';

import './Topbar.scss';
import { Button } from '../';
import { useLocation as location } from 'react-router';
import { Link, NavLink } from 'react-router-dom';

/** assets */
import exitSvg from '../../../assets/images/icons/exit-icon.svg';
import powerSvg from '../../../assets/images/icons/power-icon.svg';
import stackIconLight from '../../../assets/images/icons/stack-icon-light.svg';
import syndicateSvg from '../../../assets/images/icons/syndicate-icon.svg';
import fundSvg from '../../../assets/images/icons/fund-icon.svg';
import handshakeIcon from '../../../assets/images/icons/handshake-icon-light.svg';
import logo from '../../../assets/images/wordmark-black.png';
import mobileLogo from '../../../assets/images/apple-touch-icon.png';
import { useImagePreloader } from '../../../utils/UseImagePreloader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const founderItems = [
  {
    title: 'Formation',
    icon: powerSvg,
    description: 'Form, launch, and grow new ventures.',
    href: '/formation',
  },
  {
    title: 'Financings',
    icon: stackIconLight,
    description: 'Prepare for diligence, streamline financing operations.',
    href: '/financings',
  },
  {
    title: 'M&A',
    icon: exitSvg,
    description: 'Navigate M&A events with peace of mind.',
    href: 'https://share.hsforms.com/19Fb1rcYLSjWRc-BYcimr2w2rvzm',
    linkText: 'Schedule Consultation',
  },
];

/**
 * TODO: fill this in
 */
const investorItems = [
  {
    title: 'Syndicates',
    icon: syndicateSvg,
    description: 'The complete back & front-office solution for syndicate operations.',
    href: '/syndicates',
  },
  {
    title: 'Funds',
    icon: fundSvg,
    description: 'AI-enabled fund & investment lifecycles.',
    href: '/funds',
    // linkText: 'Schedule a Demo',
  },
  {
    title: 'Acquisitions',
    icon: handshakeIcon,
    description: 'Navigate buy & sell-side transactions with minimal friction.',
    href: 'https://share.hsforms.com/19Fb1rcYLSjWRc-BYcimr2w2rvzm',
    linkText: 'Schedule Consultation',
  },
];

const bem = generateBem('Topbar');
const Topbar = ({ isPlans }) => {
  const { search } = location();
  const [foundersMenuIsVisible, setStartupMenuVisibility] = useState(false);
  const [investorMenuIsVisible, setInvestorMenuVisibility] = useState(false);
  const [blur, setBlurState] = useState(false);
  const backdrop = useRef();
  const placement = useRef();
  const foundersMenu = useRef();
  const investorMenu = useRef();
  const images = useImagePreloader([...founderItems, ...investorItems].map(a => a.icon));

  const showBackdrop = on => {
    if (on) {
      backdrop.current.classList.add('fullSpectrum');
    } else {
      backdrop.current.classList.remove('fullSpectrum');
    }
  };

  useEffect(() => {
    if (blur) {
      showBackdrop(false);
      setStartupMenuVisibility(false);
      setInvestorMenuVisibility(false);
      setBlurState(false);
      return;
    }
    if (foundersMenuIsVisible) {
      foundersMenu.current.classList.add(bem('active'));
    } else {
      foundersMenu.current.classList.remove(bem('active'));
    }
    if (investorMenuIsVisible) {
      investorMenu.current.classList.add(bem('active'));
    } else {
      investorMenu.current.classList.remove(bem('active'));
    }
    if (foundersMenuIsVisible || investorMenuIsVisible) {
      showBackdrop(true);
      return;
    }
    showBackdrop(false);
  }, [foundersMenuIsVisible, investorMenuIsVisible, blur]);
  window.onscroll = () => {
    setBlurState(true);
  };
  return (
    <div className={bem('dropdown--wrapper')} ref={placement}>
      {images}
      <header className="Topbar">
        <div className={`container ${bem('wrapper')}`}>
          <Link to={{ pathname: '/home', search }}>
            <img
              src={logo}
              className={bem('companyLogo')}
              alt="savvi legal desktop logo"
            />
            <img
              src={mobileLogo}
              className={bem('companyMobileLogo')}
              alt="savvi legal mobile logo"
            />
          </Link>
          <div className={bem('menu')}>
            <NavLink
              to="/for-founders"
              className={bem('pricingLink')}
              ref={foundersMenu}
              onClick={e => {
                e.preventDefault();
                if (foundersMenuIsVisible) {
                  setBlurState(true);
                  return;
                }
                setStartupMenuVisibility(true);
                setInvestorMenuVisibility(false);
              }}
            >
              For Founders
              <FontAwesomeIcon icon="chevron-down" />
            </NavLink>
            <NavLink
              to="/for-investors"
              className={bem('pricingLink')}
              ref={investorMenu}
              onClick={e => {
                e.preventDefault();
                if (investorMenuIsVisible) {
                  setBlurState(true);
                  return;
                }
                setInvestorMenuVisibility(true);
                setStartupMenuVisibility(false);
              }}
            >
              For Investors
              <FontAwesomeIcon icon="chevron-down" />
            </NavLink>
            <NavLink
              to={{ pathname: '/plans', search }}
              className={bem(['pricingLink', isPlans ? 'pricingLink--optional' : null])}
              activeClassName={bem('pricingLink--active')}
            >
              Plans
            </NavLink>
          </div>
          <div className={bem('actions')}>
            <Button
              buttonType="secondary"
              href={`https://app.savvi.legal/login${search}`}
              size="sm"
            >
              Sign In
            </Button>
            {isPlans && (
              <Button href={`https://app.savvi.legal/login${search}`} size="sm">
                Get Started
              </Button>
            )}
          </div>
        </div>
      </header>
      {foundersMenuIsVisible === true && (
        <LargeDropdown items={founderItems} placement={placement} />
      )}
      {investorMenuIsVisible === true && (
        <LargeDropdown
          items={investorItems}
          placement={placement}
          componentClassName={bem('investors--component')}
        />
      )}
      <div
        className={bem('backdrop')}
        ref={backdrop}
        onClick={() => {
          setBlurState(true);
        }}
      ></div>
    </div>
  );
};

export default Topbar;
