import React from 'react';
import { generateBem } from '../../utils/generateBem';
import './SavviBranding.scss';

const bem = generateBem('SavviBranding');
const SavviFinancings = () => {
  return (
    <h1 className={bem('heading')}>
      <b className={bem('savvi')}>Savvi </b>
      <b className={bem('dotgo')}>Financings</b>
    </h1>
  );
};
const SavviFormation = () => {
  return (
    <h1 className={bem('heading')}>
      <b className={bem('savvi')}>Savvi </b>
      <b className={bem('dotgo')}>Formation</b>
    </h1>
  );
};
const SavviFunds = () => {
  return (
    <h1 className={bem('heading')}>
      <b className={bem('savvi')}>Savvi </b>
      <b className={bem('dotgo')}>Funds</b>
    </h1>
  );
};
const SavviSyndicates = () => {
  return (
    <h1 className={bem('heading')}>
      <b className={bem('savvi')}>Savvi </b>
      <b className={bem('dotgo')}>Syndicates</b>
    </h1>
  );
};
export { SavviFormation, SavviFinancings, SavviFunds, SavviSyndicates };
