import React from 'react';
import PropTypes from 'prop-types';

import './SolutionCard.scss';
import { generateBem } from '../../utils/generateBem';
import { Button } from '../common';
import { useLocation } from 'react-router';

const bem = generateBem('SolutionCard');

const SolutionCard = ({
  icon,
  title,
  description,
  invisible,
  isIconPadded,
  href,
  to,
  linkText,
}) => {
  const { search = '' } = useLocation();
  return (
    <div className={bem(['wrapper', invisible && 'wrapper--invisible'])}>
      <div className={bem(['icon', isIconPadded ? 'icon--padded' : null])}>
        <img src={icon} alt={title} />
      </div>
      <div className={bem('content')}>
        <h3 className={bem('title')}>{title}</h3>
        <p className={bem('description')}>{description}</p>
        {to && (
          <Button buttonType="link" size="sm" to={{ pathname: to, search }}>
            Learn More
          </Button>
        )}
        {href && (
          <Button buttonType="link" size="sm" href={href + search}>
            {linkText}
          </Button>
        )}
      </div>
    </div>
  );
};

SolutionCard.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  href: PropTypes.string,
};

SolutionCard.defaultProps = {
  icon: '',
  isLoading: false,
  isDisabled: false,
  className: '',
  onClick: () => {},
  type: 'button',
};

export { SolutionCard };
