import ovationLogo from '../assets/images/partnerLogos/ovationLogo.png';
import clientbookLogo from '../assets/images/partnerLogos/clientbookLogo.png';
import newChipLogo from '../assets/images/partnerLogos/newchipLogo.png';
import spacestationInvestmentsLogo from '../assets/images/partnerLogos/spacestationInvestmentsLogo.png';
// import paxMomentumLogo from '../assets/images/partnerLogos/paxMomentumLogo.png';
// import dojoLogo from '../assets/images/partnerLogos/dojoLogo.png';

export const Testimonials = [
  {
    text: "Savvi has been instrumental in Spacestation Investments' success. We are creating 40+ SPV's per year and Savvi makes the legal process, onboarding investors, and tax process flawless for us so we can focus on investing in great companies instead of managing our SPV's. Their support team is world-class and meets our needs every time. I couldn't recommend Savvi Legal enough for someone who needs a solid partner to manage SPV's with flexibility, affordability, and agility.",
    name: 'Jaxon Stuart',
    title: 'Fund Manager',
    src: spacestationInvestmentsLogo,
    style: {
      width: 180,
      marginLeft: -20,
    },
  },
  {
    text: "Savvi has simplified the legal aspects of running our business and saved us both significant time and money. Whether it's onboarding new employees or having everything ready to go for fundraising due diligence, Savvi has simplified every aspect of our legal process and become an integral part of our business. Every startup should use Savvi.",
    name: 'Brandon Wright',
    title: 'CEO',
    src: clientbookLogo,
    style: {
      width: 180,
      marginRight: 20,
      // marginLeft: 16,
    },
  },
  {
    text: "I've used Savvi for custom deals, generic projects, and everything in between. They are smart, capital efficient, and always professional. I trust them with my business and refer them to anyone who is looking for legal work--ESPECIALLY in the startup space.",
    name: 'Zack Oates',
    title: 'Founder',
    src: ovationLogo,
    style: {
      width: 120,
      marginRight: 20,
      // marginLeft: 20,
    },
  },
  {
    text: 'Savvi Legal is one of our most valuable partners. They are not only providing value to our founders using their platform and services, but also supporting all our portfolio companies by hosting periodic sessions about legal requirements and early stage financing.',
    name: 'Fernando Moreno',
    title: 'Director of Startup Success',
    src: newChipLogo,
    style: {
      width: 120,
      marginRight: 20,
      // marginLeft: 17,
    },
  },
  // {
  //   text: 'As a Founder, having a platform to easily track, send, and sort through our legal documents has been incredible. And having a customer support team behind the scenes has been invaluable.',
  //   name: 'Jaron Erickson',
  //   title: 'CEO',
  //   src: dojoLogo,
  //   style: {
  //     width: 120,
  //     marginRight: 20,
  //     // marginLeft: 17,
  //   },
  // },
  // {
  //   text: 'Savvi makes things smoother and easier for us at Pax! We use it on the fundraising side, and to facilitate the due diligence process for portfolio companies. The workflows are great for making the document signing/tracking/storage process quicker and easier as well.',
  //   name: 'Elizabeth Jafek',
  //   title: 'Program Director',
  //   src: paxMomentumLogo,
  //   style: {
  //     width: 140,
  //     marginRight: 20,
  //     // marginLeft: 17,
  //   },
  // },
];
