import investorsExample from '../../assets/images/examples/investors.png';

import stackIcon from '../../assets/images/icons/stack-icon.svg';
import handshakeIcon from '../../assets/images/icons/handshake-icon.svg';
import documentIcon from '../../assets/images/icons/document-icon.svg';
import libraryIcon from '../../assets/images/icons/library-icon.svg';
import usersIcon from '../../assets/images/icons/users-icon.svg';

import homepageExample from '../../assets/images/examples/home-page.png';
import dataroomExample from '../../assets/images/examples/dataroom.png';
import checklistExample from '../../assets/images/examples/checklist.png';

export const SyndicateData = {
  branding: 'SavviSyndicates',
  heroImage: investorsExample,
  description:
    'Leverage intuitive workflows with integrated services to streamline deal operations and investment lifecycles.',
  actionUrl: 'https://app.savvi.legal/workbench/2/49/-1/new',
  solutionsList: [
    {
      icon: documentIcon,
      image: homepageExample,
      title: 'Formation & Filings',
      description:
        "One-stop shop for syndicate formation and filings, including state fees, Form D, and K1's.",
    },
    {
      icon: handshakeIcon,
      image: dataroomExample,
      title: 'Fundraising & LP Relations',
      description: 'Use our tools to manage LP fundraising, onboarding, and reporting.',
    },
    {
      icon: stackIcon,
      image: checklistExample,
      title: 'Diligence & Deals',
      description:
        'Streamline your diligence and deal operations with integrated data rooms, checklists, and deal docs.',
    },
    {
      icon: libraryIcon,
      title: 'Lifecycle Workflows',
    },
    {
      icon: usersIcon,
      title: 'AI-Enabled Services',
    },
  ],
  isPricingCardTall: true,
  pricingList: [
    {
      buttonHref: 'https://share.hsforms.com/10Ty4ly4DTpOwt73BE9qXfQ2rvzm',
      buttonText: 'Talk to Sales',
      colHeader: 'SPV Platform',
      list: [
        'Entity Setup (no additional fees)',
        'Subscription Agreement Automation',
        'Allocation Request Forms',
        'Accredited Investor Certification & W9s',
        'Annual K1 No-Profit Notices',
        'K1 Liquidation Filing & Distribution*',
        'Document Ledgers',
        'Onboarding Call + Chat Support',
        'Manage up to 5 entities',
      ],
      prePrice: 'Starting at',
      price: '$500',
      postPrice: 'per SPV',
      footerList: ['*One-time filing fee starting at $500'],
    },
    {
      buttonHref: `https://share.hsforms.com/10Ty4ly4DTpOwt73BE9qXfQ2rvzm`,
      buttonText: 'Talk to Sales',
      colHeader: 'Full-Service',
      list: [
        'Everything in Self-Serve SPVs',
        'Filing Admin',
        'Signature Management',
        'K1 Notices & Filings',
        'Profit Distributions',
        'Ongoing Support Calls',
      ],
      prePrice: 'Starting at',
      price: '$2,000',
      postPrice: 'per SPV',
    },
    {
      buttonHref: `https://share.hsforms.com/10Ty4ly4DTpOwt73BE9qXfQ2rvzm`,
      buttonText: 'Schedule Assessment',
      colHeader: 'Add-on Services',
      list: [
        'KYC & AML Compliance',
        'Blue Sky Filings & Edgar Codes',
        'SPV Portfolio Legal Audit',
        'Counsel & Document Drafting',
        'Custom Forms & Templates',
        'Tax & Accounting Partners',
        'Crowdfunding Vehicles',
        'Exempt Reporting Advisor Compliance',
      ],
      postPrice: 'Free assessment & upfront pricing',
    },
  ],
};
