import React from 'react';

import Footer from '../../components/common/Footer';

import exitSvg from '../../assets/images/icons/exit-icon.svg';
import powerSvg from '../../assets/images/icons/power-icon.svg';
import raiseSvg from '../../assets/images/icons/raise-icon.svg';
import syndicateSvg from '../../assets/images/icons/syndicate-icon.svg';
import fundSvg from '../../assets/images/icons/fund-icon.svg';
import handshakeIconLight from '../../assets/images/icons/handshake-icon-light.svg';
import scalesIcon from '../../assets/images/icons/scales-icon.svg';
import roundedlist01building from '../../assets/images/icons/building-icon.svg';

import homePageExample from '../../assets/images/examples/home-page.png';

import {
  Button,
  GrayscaleRibbon,
  TestimonialsList,
  Sitemap,
  Topbar,
} from '../../components/common';
import { SolutionsList } from '../../components/SolutionsList';
import { Testimonials } from '../../utils/Testimonials';

import './Home.scss';
import { Partners } from '../../utils/Partners';
import { FloatingList } from '../../components/common/FloatingList/FloatingList';
import { generateBem } from '../../utils/generateBem';

const founderItems = [
  {
    title: 'Formation',
    icon: powerSvg,
    description: 'Form, launch, and grow new ventures.',
    to: '/formation',
  },
  {
    title: 'Financings',
    icon: raiseSvg,
    description: 'Prepare for diligence, streamline financing operations.',
    to: '/financings',
  },
  {
    title: 'M&A',
    icon: exitSvg,
    description: 'Navigate M&A events with peace of mind.',
    href: 'https://share.hsforms.com/10Ty4ly4DTpOwt73BE9qXfQ2rvzm',
    linkText: 'Schedule Consultation',
  },
];

/**
 * TODO: fill this in
 */
const investorItems = [
  {
    title: 'Syndicates',
    icon: syndicateSvg,
    description: 'The complete back & front-office solution for syndicate operations.',
    to: '/syndicates',
  },
  {
    title: 'Funds',
    icon: fundSvg,
    description: 'Streamline investment diligence, closing, and reporting operations.',
    to: '/funds',
  },
  {
    title: 'Acquisitions',
    icon: handshakeIconLight,
    isIconPadded: true,
    description: 'Navigate buy & sell-side transactions with minimal friction.',
    href: 'https://share.hsforms.com/19Fb1rcYLSjWRc-BYcimr2w2rvzm',
    linkText: 'Schedule Consultation',
  },
];

// const bem = elementName => `Home${elementName ? '__' + elementName : ''}`;
const bem = generateBem('Home');

const floatingList = [
  {
    icon: powerSvg,
    title: 'Formation & Compliance',
  },
  {
    icon: scalesIcon,
    iconClassName: bem('floatingIcon'),
    title: 'Diligence',
  },
  {
    icon: raiseSvg,
    title: 'Fundraising',
  },
  {
    icon: roundedlist01building,
    iconClassName: bem('floatingIcon'),
    title: 'Entity Lifecycle',
  },
  {
    icon: exitSvg,
    title: 'M&A',
  },
];

const Home = ({ location: { search } }) => (
  <>
    <Topbar />
    <section className={`${bem('heroSection')} container`}>
      <div className="row">
        <div className={bem('heroTextWrapper')}>
          <h1 className={bem('heroHeading')}>Where Legal Flows</h1>
          <h3 className={bem('heroSubtext')}>
            AI-Enabled legal solutions for founders & investors.
          </h3>
          <Button
            className={bem('heroButton')}
            href={`https://app.savvi.legal/login${search}`}
          >
            Get Started
          </Button>
        </div>
        <div className={bem('heroImageWrapper')}>
          <img
            className={bem('heroImage')}
            src={homePageExample}
            alt="Savvi Site Example"
          />
          <div className={bem(['floatingList', 'xs--hide'])}>
            <FloatingList items={floatingList} zIndex="80" />
          </div>
          <Button
            className={bem('heroButtonMobile')}
            href={`https://app.savvi.legal/login${search}`}
          >
            Get Started
          </Button>
        </div>
      </div>
    </section>
    <section className="container">
      <SolutionsList items={founderItems} title="For Founders" />
      <SolutionsList items={investorItems} title="For Investors" />
      <GrayscaleRibbon tiles={Partners} title="Communities we serve" />
      <TestimonialsList listItems={Testimonials} />
    </section>
    <Sitemap />
    <Footer />
  </>
);

export default Home;
