import React, { useEffect } from 'react';

import { BrowserRouter, Redirect, Route, Switch, useLocation } from 'react-router-dom';

import Home from './views/Home';
import Plans from './views/Plans';
import SolutionsTemplate from './views/SolutionsTemplate';
import { FundData, GoData, SyndicateData, RaiseData } from './data/SolutionsViewsData';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

const App = () => (
  <div className="app">
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route path="/plans" component={Plans} />
        <Route path="/formation">
          <SolutionsTemplate {...GoData} />
        </Route>
        <Route path="/financings">
          <SolutionsTemplate {...RaiseData} />
        </Route>
        <Route path="/syndicates">
          <SolutionsTemplate {...SyndicateData} />
        </Route>
        <Route path="/funds">
          <SolutionsTemplate {...FundData} />
        </Route>
        <Route path="/home" component={Home} />
        <Redirect from="/*" to="/home" />
      </Switch>
    </BrowserRouter>
  </div>
);

export default App;
