import React from 'react';

import './Footer.scss';

const bem = elementName => `Footer${elementName ? '__' + elementName : ''}`;

const Footer = () => {
  return (
    <footer className={'Footer'}>
      <div className={bem('footerWrapper')}>
        <p className={bem('disclaimer')}>
          Savvi partners with licensed attorneys to provide custom services, but they only
          assume responsibility for any services provided after clients sign an engagement
          letter. Savvi Technologies, Inc. is not an attorney or a law firm, and can only
          provide self-help services at your specific direction.
        </p>
        <div className={bem('copyright')}>
          &copy;Savvi Legal 2019.All Rights Reserved.
        </div>
        <div className={bem('linksWrapper')}>
          <a
            className={bem('link')}
            href="https://app.savvi.legal/terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>
          <p className={bem('dotSeparator')}>&middot;</p>
          <a
            className={bem('link')}
            href="https://app.savvi.legal/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
